import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import RefreshSharpIcon from "@material-ui/icons/RefreshSharp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { Plugins, Modals } from "@capacitor/core";
import apiCaller from "../components/apiCaller";
import Logo from "./../images/Recommendations.svg";

const { Storage } = Plugins;

async function showAlert(title, message) {
  await Modals.alert({ title, message });
}

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    width: "95%",
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

async function setObject(key, data) {
  await Storage.set({
    key,
    value: JSON.stringify(data),
  });
}

async function getObject(key) {
  const ret = await Storage.get({ key });
  return JSON.parse(ret.value);
}

async function clear() {
  await Storage.clear();
}

export default function Welcome() {
  const classes = useStyles();
  const history = useHistory();

  const [errors, setErrors] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mobile, setMobile] = React.useState("");
  const [name, setName] = React.useState("");
  const [session, setSession] = React.useState(null);
  const [data, setData] = React.useState({});

  async function handleBegin(e) {
    e.preventDefault();
    setLoading(true);
    setDisabled(true);
    let response = await apiCaller("post", "/welcome", {
      name,
      mobile: parseInt(mobile.replace(/\s+/g, ""), 10),
      ...(session !== null && { session }),
    });

    if (response.data === undefined) {
      showAlert(
        "Something went wrong",
        "We encountered error at out servers and the same has been notified to admin."
      );
      setDisabled(false);
      setLoading(false);
    } else if (response.data.errors !== undefined) {
      setErrors(response.data.errors);
      setDisabled(false);
      setLoading(false);
    } else {
      setErrors({});
      setLoading(false);
      setDisabled(false);
      setObject("profile", {
        ...data,
        name,
        mobile,
        session: response.data.session,
      });
      history.replace(response.data.next);
    }
  }

  function handleStartOver(e) {
    e.preventDefault();
    clear();
    setDisabled(false);
    setName("");
    setMobile("");
    setSession(null);
  }

  React.useEffect(() => {
    getObject("profile").then((data) => {
      if (data !== null) {
        setData(data);
        setName(data.name);
        setMobile(data.mobile);
        setSession(data.session);
        setDisabled(true);
      }
    });
  }, []);

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <img
        src={Logo}
        alt="Recommendations by EldersIndia"
        width="275"
        className={classes.logo}
      />
      <Typography variant="h6" gutterBottom align="center">
        Find out service recommendations for your parent's golden years of life
      </Typography>
      <form className={classes.form} onSubmit={handleBegin}>
        {Object.keys(errors).length !== 0 && (
          <Alert
            severity="error"
            onClose={() => {
              setErrors({});
            }}
          >
            Please correct the errors and re-submit.
          </Alert>
        )}
        <TextField
          required
          id="outlined-required"
          label="Full name"
          placeholder="Your full name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={disabled}
          className={classes.field}
          error={errors.hasOwnProperty("name") ? true : false}
          helperText={
            errors.hasOwnProperty("name")
              ? errors.name.map((e, i) => <span key={"name" + i}>{e}</span>)
              : ""
          }
        />
        <NumberFormat
          customInput={TextField}
          format="#### #### ##"
          className={classes.field}
          label="10 digit mobile number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+91</InputAdornment>
            ),
          }}
          variant="outlined"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
          disabled={disabled}
          error={errors.hasOwnProperty("mobile") ? true : false}
          helperText={
            errors.hasOwnProperty("mobile")
              ? errors.mobile.map((e, i) => <span key={"mobile" + i}>{e}</span>)
              : ""
          }
        />
        <Button
          type="submit"
          variant="contained"
          color={disabled ? "secondary" : "primary"}
          className={classes.button}
          endIcon={
            loading ? (
              <CircularProgress size={24} />
            ) : (
              <KeyboardArrowRightSharpIcon />
            )
          }
          size="large"
          disabled={loading}
        >
          {disabled ? "Resume progress" : "Let's begin"}
        </Button>
        {disabled && (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            endIcon={<RefreshSharpIcon />}
            size="large"
            onClick={handleStartOver}
          >
            Start over
          </Button>
        )}
      </form>
    </Grid>
  );
}
