import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import apiCaller from '../components/apiCaller';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    button: {
        marginTop: theme.spacing(2),

    },
}));

// async function setObject(key, data) {
//     await Storage.set({
//         key,
//         value: JSON.stringify(data)
//     });
// }

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function Concerns(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [concerns, setConcerns] = React.useState({
        "No support during emergency": true,
        Loneliness: false,
        Depression: false,
        "Lack of financial independence": false,
        "Social isolation": false,
        "Severe medical conditions": false,
    });
    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();
        if (error) {
            showAlert('Pick at least one', 'At least one answer is required.');
        }
        else {
            setDisabled(true);
            setLoading(true);
            let response = await apiCaller('post', '/what-are-the-concerns-that-you-have-for-your-relationship', { concerns, session: data.session });

            if (response.data === undefined) {
                showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
                setDisabled(false);
                setLoading(false);
            }
            else if (response.data.errors !== undefined) {
                setErrors(response.data.errors);
                setDisabled(false);
                setLoading(false);
            }
            else {
                setDisabled(false);
                setLoading(false);
                history.push(response.data.next)
            }
        }
    }

    const handleChange = (event) => {
        setConcerns({ ...concerns, [event.target.name]: event.target.checked });
    };

    const { "No support during emergency": no_support_during_emergency, Loneliness, Depression, "Lack of financial independence": lack_of_financial_independence, "Social isolation": social_isolation, "Severe medical conditions": sever_medical_conditions } = concerns;
    const error = [no_support_during_emergency, Loneliness, Depression, lack_of_financial_independence, social_isolation, sever_medical_conditions].filter((v) => v).length < 1;

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">{data !== null && `${data.name},`} what are the concerns that you have for your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()}, {data !== null && data.eldersDetails[data.questionsTakenFor].name}?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <FormControl required component="fieldset" className={classes.formControl} error={errors.hasOwnProperty('concerns') || error ? true : false}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={no_support_during_emergency} onChange={handleChange} name="No support during emergency" />}
                            label="No support during emergency"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Loneliness} onChange={handleChange} name="Loneliness" />}
                            label="Loneliness"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Depression} onChange={handleChange} name="Depression" />}
                            label="Depression"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={lack_of_financial_independence} onChange={handleChange} name="Lack of financial independence" />}
                            label="Lack of financial independence"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={social_isolation} onChange={handleChange} name="Social isolation" />}
                            label="Social isolation"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sever_medical_conditions} onChange={handleChange} name="Severe medical conditions" />}
                            label="Severe medical conditions"
                        />
                    </FormGroup>
                    {error && <FormHelperText>Pick at least one</FormHelperText>}
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}