import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import apiCaller from '../components/apiCaller';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    button: {
        marginTop: theme.spacing(2),

    },
}));

// async function setObject(key, data) {
//     await Storage.set({
//         key,
//         value: JSON.stringify(data)
//     });
// }

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function Barriers(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [barriers, setBarriers] = React.useState({
        Time: true,
        Cost: false,
        "Lack of company": false,
        "Distance from home": false,
        "Mobility Services": false,
        "Health condition": false,
        "Lack of information about possiblities": false,
    });
    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();

        if (error) {
            showAlert('Pick at least one', 'At least one answer is required.');
        }
        else {
            setDisabled(true);
            setLoading(true);

            let response = await apiCaller('post', '/what-are-the-barriers,-that-prevent-your-relationship-from-participating-in-leisure-activities', { barriers, session: data.session });

            if (response.data === undefined) {
                showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
                setDisabled(false);
                setLoading(false);
            }
            else if (response.data.errors !== undefined) {
                setErrors(response.data.errors);
                setDisabled(false);
                setLoading(false);
            }
            else {
                setDisabled(false);
                setLoading(false);
                history.push(response.data.next)
            }
        }
    }

    const handleChange = (event) => {
        setBarriers({ ...barriers, [event.target.name]: event.target.checked });
    };

    const { Time, Cost, "Lack of company": lack_of_company, "Distance from home": distance_from_home, "Mobility Services": mobility_services, "Health condition": health_condition , "Lack of information about possiblities": Lack_of_information_about_possiblities} = barriers;
    const error = [Time, Cost, lack_of_company, distance_from_home, mobility_services, health_condition,Lack_of_information_about_possiblities].filter((v) => v).length < 1;

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">What are the barriers that prevent your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()} {data !== null && data.eldersDetails[data.questionsTakenFor].name} from participating in leisure activities?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <FormControl required component="fieldset" className={classes.formControl} error={errors.hasOwnProperty('barriers') || error ? true : false}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={Time} onChange={handleChange} name="Time" />}
                            label="Time"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Cost} onChange={handleChange} name="Cost" />}
                            label="Cost"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={lack_of_company} onChange={handleChange} name="Lack of company" />}
                            label="Lack of company"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={distance_from_home} onChange={handleChange} name="Distance from home" />}
                            label="Distance from home"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={mobility_services} onChange={handleChange} name="Mobility Services" />}
                            label="Mobility Services"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={health_condition} onChange={handleChange} name="Health condition" />}
                            label="Health condition"
                        />
                         <FormControlLabel
                            control={<Checkbox checked={Lack_of_information_about_possiblities} onChange={handleChange} name="Lack of information about possiblities" />}
                            label="Lack of information about possiblities"
                        />
                         <FormHelperText>{errors.hasOwnProperty('barriers') ? errors.barriers.map((e, i) => <span key={"barriers" + i}>{e}</span>) : ""}</FormHelperText>
                    </FormGroup>
                    
                    {error && <FormHelperText>Pick at least one</FormHelperText>}
                   
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}