import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import { Plugins } from '@capacitor/core';
import apiCaller from './../components/apiCaller';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),

    },
}));

async function setObject(key, data) {
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
}

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}

export default function ThankYou(props) {
    const classes = useStyles();
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [data, setData] = React.useState(null);

    async function handleViewRecommendations() {
        setDisabled(true);
        setLoading(true);
        let response = await apiCaller('post', '/view-recommendations', { session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            setObject("profile", { ...data, recommendations: response.data.recommendations })
            history.push(response.data.next)
        }
    }

    async function handleContinue() {
        setDisabled(true);
        setLoading(true);
        let response = await apiCaller('post', '/continue', { session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            history.push(response.data.next)
        }
    }

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left">

            <List dense>
                {data !== null && data.eldersDetails.map((e, i) => (

                    <ListItem key={"elders" + i} selected={data.questionsTakenFor > i ? true : false} divider>
                        <ListItemIcon>
                            {data.questionsTakenFor > i ? <DoneOutlineIcon color="secondary"/> : <DoubleArrowIcon />}
                        </ListItemIcon>
                        <ListItemText primary={`${e.relationship}, ${e.name}`} secondary={data.questionsTakenFor > i ? "completed" : "incomplete"} />
                    </ListItem>

                ))}
            </List>

            <Button
                type="button"
                variant="outlined"
                color="secondary"
                className={classes.button}
                size="large"
                endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                disabled={disabled}
                onClick={handleViewRecommendations}
            >
                {data !== null && data.questionsTakenFor < data.numberOfElders ? "Skip and View recommendation" : "View Recommendations"}
            </Button>
            {data !== null && data.questionsTakenFor < data.numberOfElders && (
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                    onClick={handleContinue}
                >
                    Continue for {data !== null && data.eldersDetails[data.questionsTakenFor].name}
                </Button>
            )
            }
        </Box>
    )
}