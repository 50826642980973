import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import apiCaller from './../components/apiCaller';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

async function setObject(key, data) {
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
}

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function NumberOfElders(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [numberOfElders, setNumberOfElders] = React.useState(1);
    const [data, setData] = React.useState(null);
    const relationships = ['Father', 'Mother', 'Father-in-law', 'Mother-in-law', 'Uncle', 'Aunt', 'Brother', 'Sister'];
    const [eldersDetails, setEldersDetails] = React.useState([{ name: '', dob: moment().subtract(35, 'years'), relationship: '' }]);

    function handleNumberOfElders(e) {
        if(!e.target.value) {
            setNumberOfElders(0);
        }
        else if (parseInt(e.target.value) < 1) {
            showAlert('Minimum 1 elder is required', 'At least one elder is required.');
        }
        else if (Number.isInteger(numberOfElders)) {
            setNumberOfElders(parseInt(e.target.value));
            let elders = [];
            Array(parseInt(e.target.value)).fill().map((_, i) => (
                elders.push({ name: eldersDetails[i] !== undefined ? eldersDetails[i].name : "", dob: eldersDetails[i] !== undefined ? eldersDetails[i].dob : moment().subtract(35, 'years'), relationship: eldersDetails[i] !== undefined ? eldersDetails[i].relationship : "" })
            ))
            setEldersDetails(elders);
        }
    }

    function handleNameChange(e, i) {
        setEldersDetails(eldersDetails.map((elder, j) => {
            if (i === j) {
                return {
                    ...elder,
                    name: e.target.value
                }
            }
            return elder;
        }))
    }

    function handleDobChange(e, i) {
        setEldersDetails(eldersDetails.map((elder, j) => {
            if (i === j) {
                return {
                    ...elder,
                    dob: e
                }
            }
            return elder;
        }))
    }

    function handleRelationshipChange(e, i) {
        setEldersDetails(eldersDetails.map((elder, j) => {
            if (i === j) {
                return {
                    ...elder,
                    relationship: e.target.value
                }
            }
            return elder;
        }))
    }

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        let response = await apiCaller('post', '/number-of-elders', { numberOfElders, eldersDetails, session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            setObject('profile', { ...data, numberOfElders, eldersDetails, questionsTakenFor: response.data.questionsTakenFor })
            history.push(response.data.next)
        }

    }

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="justify">
            <Typography variant="h5" align="center">{data !== null && `${data.name},`} how many elders are there in your family?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <TextField
                    required
                    type="number"
                    id="outlined-required"
                    min={1}
                    max={6}
                    label="Number of elders"
                    placeholder="Number of elders"
                    variant="outlined"
                    value={numberOfElders}
                    onChange={handleNumberOfElders}
                    autoFocus
                    className={classes.field}
                    disabled={disabled}
                    error={errors.hasOwnProperty('numberOfElders') ? true : false}
                    helperText={errors.hasOwnProperty('numberOfElders') ? errors.numberOfElders.map((e, i) => <span key={"numberOfElders" + i}>{e}</span>) : ""}
                />
                {
                    Number.isInteger(numberOfElders) && Array(numberOfElders).fill().map((_, i) => {
                        return (
                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" key={i + 'form'}>
                                <TextField
                                    required
                                    id={i + 'elderName'}
                                    label="Name"
                                    placeholder="Elder name"
                                    variant="outlined"
                                    value={eldersDetails[i].name ?? ''}
                                    onChange={e => handleNameChange(e, i)}
                                    disabled={disabled}
                                    className={classes.field}
                                    error={errors.hasOwnProperty(`eldersDetails.${i}.name`) ? true : false}
                                    helperText={errors.hasOwnProperty(`eldersDetails.${i}.name`) ? errors[`eldersDetails.${i}.name`].map((e, j) => <span key={"elders" + i + j}>{e}</span>) : ""}
                                />
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id={i + 'demo-simple-select-outlined-label'}>Relationship</InputLabel>
                                    <Select
                                        labelId={i + 'demo-simple-select-outlined-label'}
                                        id={'relationship-' + i}
                                        value={eldersDetails[i].relationship}
                                        onChange={e => handleRelationshipChange(e, i)}
                                        label="Relationship"
                                        required
                                        className={classes.field}
                                        error={errors.hasOwnProperty(`eldersDetails.${i}.relationship`) ? true : false}
                                        // helperText={errors.hasOwnProperty(`eldersDetails.${i}.relationship`) ? errors[`eldersDetails.${i}.relationship`].map((e, j) => <span key={"relationships" + i + j}>{e}</span>) : ""}
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {relationships.map((r, j) => (
                                            <MenuItem value={r} key={r + j}>{r}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        key={i + 'elders'}
                                        variant="inline"
                                        format="DD/MM/yyyy"
                                        margin="normal"
                                        id={`bob-${i}`}
                                        label="Date of birth"
                                        value={eldersDetails[i].dob}
                                        onChange={e => handleDobChange(e, i)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={disabled}
                                        className={classes.field}
                                        required

                                        error={errors.hasOwnProperty(`eldersDetails.${i}.dob`) ? true : false}
                                        helperText={errors.hasOwnProperty(`eldersDetails.${i}.dob`) ? errors[`eldersDetails.${i}.dob`].map((e, j) => <span key={"dobs" + i + j}>{e}</span>) : ""}
                                    />

                                </MuiPickersUtilsProvider>
                            </Box>
                        )
                    })
                }
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}