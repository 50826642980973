import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import { Plugins } from '@capacitor/core';
import apiCaller from './../components/apiCaller';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import TrendingFlatSharpIcon from '@material-ui/icons/TrendingFlatSharp';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),

    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(1)
    },
    badge: {
        marginTop: "20px",
        display: "inline-block"
    }
}));

const labels = {
    0.5: 'Need improvement',
    1: 'Need improvement+',
    1.5: 'Somewhat Ok',
    2: 'Somewhat Ok+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

async function setObject(key, data) {
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
}

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}

async function clear() {
    await Storage.clear();
}

export default function ViewRecommendations(props) {
    const classes = useStyles();
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [showFeedbackForm, setShowFeedbackForm] = React.useState(true);
    const [value, setValue] = React.useState(3);
    const [hover, setHover] = React.useState(-1);
    const [feedback, setFeedback] = React.useState("");

    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);
        let response = await apiCaller('post', '/feedback', { rating: value, feedback, session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            setObject('profile', { ...data, isFeedbackCaptured: response.data.isFeedbackCaptured, message: response.data.message })
            setShowFeedbackForm(!response.data.isFeedbackCaptured);
        }
    }

    function handleShowMessages(event, e) {
        setData({ ...data, recommendations: { ...data.recommendations, [e]: { ...data.recommendations[e], showMessages: !data.recommendations[e]['showMessages'] } } })
    }

    async function handleStartOver(e) {
        e.preventDefault();
        await clear();
        history.push("/");
    }

    async function handleContinue() {
        setDisabled(true);
        setLoading(true);
        let response = await apiCaller('post', '/continue', { session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            history.push(response.data.next)
        }
    }

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
            data !== null && data.isFeedbackCaptured && setShowFeedbackForm(false);
        })
    }, [])

    return (
        <Box >
            <Box fontSize="h4.fontSize" flexDirection="row" display="flex" justifyContent="center" alignItems="center">Thank you</Box>
            <Box fontSize={18} mb={2} textAlign="center">We appreciate the time you have taken to complete the survey. Hope the suggested recommendations are helpful to take an informed decision. We request you to kindly share your valuable feedback.</Box>
            {data !== null && data.recommendations && Object.keys(data.recommendations).map((e, i) =>
                <Paper className={classes.paper} key={e + i}>
                    <Box display="flex" py={1.5}>
                        <Box flexGrow={1} fontSize={18} fontWeight="bold" color="primary.info">{e}{data.recommendations[e]['locationWarning'] ? "*" : ""}</Box>
                        <FormControlLabel control={<Switch size="small" color="primary" checked={data.recommendations[e]['showMessages']} onClick={event => handleShowMessages(event, e)} name="showMessages" inputProps={{ 'aria-label': 'Show why' }} />} label="Show why" labelPlacement="start" />
                    </Box>
                    {Object.keys(data.recommendations[e]['recommendations']).map((c, j) =>
                        <React.Fragment key={c + i + j} >
                            <List dense disablePadding subheader={<React.Fragment><Typography variant="caption" color="textPrimary">{c}</Typography> <Divider /></React.Fragment>}>
                                {Object.keys(data.recommendations[e]['recommendations'][c]).map((s, k) =>
                                    <React.Fragment key={s + i + j + k}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemIcon><TrendingFlatSharpIcon size="small" /></ListItemIcon>
                                            <ListItemText
                                               

                                                primary={<Typography color="Secondary" variant="subtitle1">{data.recommendations[e]['recommendations'][c][s]['description']}
                                                 {/* {data.recommendations[e]['showMessages'] && <React.Fragment> <br /> <br /> {data.recommendations[e]['recommendations'][c][s]['messages'].map((m, l) => <span key={"message" + i + j + k + l} style={{ fontWeight: 'normal' }}>{m} </span>)}</React.Fragment>} */}
                                                 </Typography>}

                                                secondary={<Typography color="Primary" variant="subtitle2">{data.recommendations[e]['recommendations'][c][s]['name']}

                                                {data.recommendations[e]['showMessages'] && <React.Fragment> <br /> <br /> {data.recommendations[e]['recommendations'][c][s]['messages'].map((m, l) => <span key={"message" + i + j + k + l} style={{ fontWeight: 'normal' }}>{m} </span>)}</React.Fragment>}
                                                </Typography>}

                                            />
                                        </ListItem>
                                    </React.Fragment>
                                )}
                            </List>
                        </React.Fragment>
                    )}
                    {data.recommendations[e]['locationWarning'] === true && (
                        <Box color="text.secondary">
                            * With the information provided, some services may not be available in your city at the moment. We are expanding our services across various cities and we will keep you informed once the services are available in your city. To know more information, reach us at support@eldersIndia.com.
                            Thank you
                        </Box>
                    )}
                </Paper>
            )
            }
            {data !== null && data.questionsTakenFor < data.numberOfElders &&
                <Paper className={classes.paper}>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h5" color="textPrimary" >Please continue with next elder</Typography>
                        </Box>
                        <Box>

                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                // className={classes.button}
                                size="large"
                                endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                                disabled={disabled}
                                onClick={handleContinue}
                            >
                                Continue for {data !== null && data.eldersDetails[data.questionsTakenFor].name}
                            </Button>
                        </Box>
                    </Box>

                    {/* <List dense>
                    {data !== null && data.eldersDetails.filter((e , i) => data.questionsTakenFor > i ? false : true).map((e, i) => (

                        <ListItem key={"elders" + i} divider>
                            <ListItemIcon>
                                <DoubleArrowIcon />
                            </ListItemIcon>
                            <ListItemText primary={`${e.relationship}, ${e.name}`} secondary={"incomplete"} />
                        </ListItem>

                    ))}
                </List> */}
                </Paper>
            }
            <Paper className={classes.paper}>
                {showFeedbackForm ?
                    <Collapse in={showFeedbackForm}>
                        <Typography variant="h5" color="textPrimary" align="center" display="block" gutterBottom>Please share your valuable feedback</Typography>
                        <form onSubmit={handleFormSubmit}>
                            <Box display="flex" alignItems="center">
                                <Rating
                                    name="hover-feedback"
                                    value={value}
                                    precision={0.5}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    size="large"
                                />
                                {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
                            </Box>
                            <TextField
                                required
                                id="feedback"
                                label="Feedback"
                                multiline
                                fullWidth
                                rows={4}
                                placeholder="Please enter your feedback here"
                                variant="outlined"
                                className={classes.field}
                                value={feedback}
                                onChange={e => setFeedback(e.target.value)}
                                error={errors.hasOwnProperty('feedback') ? true : false}
                                helperText={errors.hasOwnProperty('feedback') ? errors.feedback.map((e, i) => <span key={"feedback" + i}>{e}</span>) : ""}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                size="large"
                                fullWidth
                                endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                                disabled={disabled}
                            >
                                Submit
                            </Button>
                        </form>
                    </Collapse>
                    : <Alert severity="success"><AlertTitle>Feedback captured</AlertTitle>{data !== null && data.isFeedbackCaptured && data.message}</Alert>}
            </Paper>
            <Paper className={classes.paper}>
                <Box fontSize={16} textAlign="center">
                    We request you to kindly register with us by clicking the register button, if you have already registered, kindly download ELDERSINDIA App and avail the exclusive offers.
                </Box>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            endIcon={<RefreshSharpIcon />}
                            size="large"
                            onClick={handleStartOver}
                        >
                            Start over
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="large"
                            href="https://app.eldersindia.com">
                            Login / Register
                        </Button>
                    </Grid>
                    <Grid item>
                        <a className={classes.badge} href='https://play.google.com/store/apps/details?id=com.tharsha.elderindia&hl=en_IN&utm_source=email&utm_campaign=recommendations&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width="160px" /></a>
                    </Grid>
                    <Grid item>
                        <a className={classes.button} href="https://apps.apple.com/in/app/eldersindia/id1453359124?mt=8" style={{ display: "inline-block", overflow: "hidden", background: "url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-02-20&kind=iossoftware&bubble=ios_apps) no-repeat", width: "135px", height: "40px" }}></a>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}