import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import { Plugins } from '@capacitor/core';
import apiCaller from './../components/apiCaller';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),

    },
}));

async function setObject(key, data) {
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
}

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}

export default function Travel(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [travel, setTravel] = React.useState('Weekly');
    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        // let response = await apiCaller('post', "/how-do-you-rate-your-relationship's-mobility", { mobility, session: data.session });
        let response = await apiCaller('post', "/how-often-does-your-relationship-travel", { travel, session: data.session });
        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setObject('profile', { ...data, questionsTakenFor: response.data.questionsTakenFor });
            setDisabled(false);
            setLoading(false);
            history.replace(response.data.next)
        }
    }

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">How often does your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()} {data !== null && data.eldersDetails[data.questionsTakenFor].name} travel ?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <FormControl component="fieldset" className={classes.formControl} error={errors.hasOwnProperty('travel') ? true : false}>
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup aria-label="quiz" name="travel" value={travel} onChange={e => setTravel(e.target.value)}>
                        <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
                        <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                        <FormControlLabel value="Quarterly" control={<Radio />} label="Quarterly" />
                        <FormControlLabel value="Annually" control={<Radio />} label="Annually" />
                        <FormControlLabel value="Not at all" control={<Radio />} label="Not at all" />
                    </RadioGroup>
                    <FormHelperText>{errors.hasOwnProperty('travel') ? errors.travel.map((e, i) => <span key={"travel" + i}>{e}</span>) : ""}</FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}