import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { Plugins, Modals } from '@capacitor/core';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import apiCaller from './../components/apiCaller';
import { useHistory } from "react-router-dom";

const { Storage } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),

    },
}));

async function setObject(key, data) {
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
}

async function clear() {
    await Storage.clear();
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function Resume(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [mobile, setMobile] = React.useState('');
    const [session, setSession] = React.useState('');

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);
        clear();
        let response = await apiCaller('post', '/resume', { mobile: parseInt(mobile.replace(/\s+/g, ''), 10), session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            setObject("profile", { ...response.data })
            history.push(response.data.next)
        }
    }


    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1" align="center">Please enter mobile number and session id (which you had received earlier via SMS) to resume the progress</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <NumberFormat
                    customInput={TextField}
                    format="#### #### ##"
                    className={classes.field}
                    label="10 digit mobile number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+91 </InputAdornment>,
                    }}
                    variant="outlined"
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                    required
                    disabled={disabled}
                    autoFocus
                    error={errors.hasOwnProperty('mobile') ? true : false}
                    helperText={errors.hasOwnProperty('mobile') ? errors.mobile.map((e, i) => <span key={"mobile" + i}>{e}</span>) : ""}
                />
                <TextField
                    required
                    id="outlined-required"
                    label="Session ID"
                    placeholder="Enter session id"
                    variant="outlined"
                    value={session}
                    onChange={e => setSession(e.target.value)}
                    disabled={disabled}
                    error={errors.hasOwnProperty('session') ? true : false}
                    helperText={errors.hasOwnProperty('session') ? errors.session.map((e, i) => <span key={"session" + i}>{e}</span>) : ""}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    size="large"
                    disabled={loading}
                >
                    {loading ? "Resumming..." : "Resume progress"}
                </Button>
            </form>
        </Box>
    )
}