import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { Plugins } from '@capacitor/core';
import DialpadSharpIcon from '@material-ui/icons/DialpadSharp';
import SmsSharpIcon from '@material-ui/icons/SmsSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import apiCaller from './../components/apiCaller';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    otp: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),

    },
}))

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function OTP(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [otp, setOtp] = React.useState('');
    const [resendOTP, setResendOTP] = React.useState(false);
    const [seconds, setSeconds] = React.useState(20);
    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);
        let response = await apiCaller('post', '/otp', { otp: parseInt(otp.replace(/\s+/g, ''), 10), session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            history.push(response.data.next)
        }
    }

    async function handleResendOTP(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);
        let response = await apiCaller('post', '/resend-otp', { session: data.session });
        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            setResendOTP(false);
            setSeconds(20)
            showAlert('Alert', response.data.message);
        }
    }

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    React.useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setResendOTP(true);
        }
    }, [seconds]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">Hi {data !== null && data.name}, please enter the 4 digits OTP received on {data !== null && data.mobile}</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <NumberFormat
                    customInput={TextField}
                    format="#   #   #   #"
                    className={classes.otp}
                    label="4 digit OTP"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><DialpadSharpIcon /></InputAdornment>,
                    }}
                    variant="outlined"
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                    required
                    error={errors.hasOwnProperty('otp') ? true : false}
                    helperText={errors.hasOwnProperty('otp') ? errors.otp.map((e, i) => <span key={"otp" + i}>{e}</span>) : ""}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color={disabled ? "secondary" : "primary"}
                    className={classes.button}
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    size="large"
                    disabled={loading}
                >
                    {loading ? "Submitting" : "Submit OTP"}
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    startIcon={<SmsSharpIcon />}
                    size="large"
                    onClick={handleResendOTP}
                    disabled={!resendOTP || disabled}
                >
                    Resend OTP {seconds > 0 ? `in ${seconds}S..` : ''}
                </Button>
            </form>
        </Box>
    )
}