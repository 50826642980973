import axios from "axios";

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;
const baseURL = "https://recommendations.eldersindia.com/api";
// const baseURL = "http://recommendations.local/api";
// const baseURL = "http://127.0.0.1:8000/api";

export default function apiCaller(requestType, apiEndPoint, formData) {
  return axios({
    method: requestType,
    url: `${baseURL}${apiEndPoint}`,
    ...(requestType === "post" && { data: formData }),
  })
    .then((response) => response)
    .catch((error) => error);
}
