import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import apiCaller from './../components/apiCaller';


const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "98%"
    },
    title: {
        marginTop: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

// async function setObject(key, data) {
//     await Storage.set({
//         key,
//         value: JSON.stringify(data)
//     });
// }

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function TypesLeisures(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null);

    const [recreational, setRecreational] = React.useState({
        "Senior citizen club": false,
        "Offline events": false,
        "Community activities": false,
        "Social media": false,
    });
    const [travel, setTravel] = React.useState({
        "Domestic tours": false,
        "Foreign trips": false,

    });
    const [individual, setIndividual] = React.useState({
        "Gardening": false,
        "Reading": false,
    
    });
    const [none, setNone] = React.useState({
        "Interested to know more": true,
    });

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        let response = await apiCaller('post', '/what-are-the-types-of-leisure-activities-your-relationship-would-like-to-be-part-of', { recreational, travel, individual, none, session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            history.push(response.data.next)
        }
    }

    const handleRecreational = (event) => {
        setRecreational({ ...recreational, [event.target.name]: event.target.checked });
        setNone({ ...none, "Interested to know more": false });
    };
    const handleTravel = (event) => {
        setTravel({ ...travel, [event.target.name]: event.target.checked });
        setNone({ ...none, "Interested to know more": false });
    };
    const handleIndividual = (event) => {
        setIndividual({ ...individual, [event.target.name]: event.target.checked });
        setNone({ ...none, "Interested to know more": false });
    };
    const handleNone = (event) => {
        setNone({ ...none, [event.target.name]: event.target.checked });
        setRecreational({ ...recreational, ...Object.keys(recreational).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
        setTravel({ ...travel, ...Object.keys(travel).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
        setIndividual({ ...individual, ...Object.keys(individual).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
    };

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography fontWeight={700} variant="h5" align="center">What are the types of leisure activities would your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()} {data !== null && data.eldersDetails[data.questionsTakenFor].name} likes to be part of?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}

                <Typography display="block" color="primary" variant="subtitle1">Recreational</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={<Checkbox checked={recreational["Senior citizen club"]} onChange={handleRecreational} name="Senior citizen club" size="small" />}
                        label="Senior citizen club"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={recreational["Offline events"]} onChange={handleRecreational} name="Offline events" size="small" />}
                        label="Offline events"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={recreational["Community activities"]} onChange={handleRecreational} name="Community activities" size="small" />}
                        label="Community activities"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={recreational["Social media"]} onChange={handleRecreational} name="Social media" size="small" />}
                        label="Social media"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>Travel</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={travel["Domestic tours"]}
                                onChange={handleTravel}
                                name="Domestic tours"
                                size="small"
                            />
                        }
                        label="Domestic tours"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={travel["Foreign trips"]}
                                onChange={handleTravel}
                                name="Foreign trips"
                                size="small"
                            />
                        }
                        label="Foreign trips"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1">Individual</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={<Checkbox checked={individual["Gardening"]} onChange={handleIndividual} name="Gardening" size="small" />}
                        label="Gardening"
                    />
                     <FormControlLabel
                        control={<Checkbox checked={individual["Reading"]} onChange={handleIndividual} name="Reading" size="small" />}
                        label="Reading"
                    />
                   
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>None</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={none["Interested to know more"]}
                                onChange={handleNone}
                                name="Interested to know more"
                                size="small"
                            />
                        }
                        label="Interested to know more"
                    />
                </Box>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}