import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import apiCaller from '../components/apiCaller';

const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    button: {
        marginTop: theme.spacing(2),

    },
}));

// async function setObject(key, data) {
//     await Storage.set({
//         key,
//         value: JSON.stringify(data)
//     });
// }

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function HearingAbility(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [hearingAbility, setHearingAbility] = React.useState('Good');
    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        let response = await apiCaller('post', "/how-do-you-rate-your-relationship's-hearing-ability", { hearingAbility, session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            history.push(response.data.next)
        }
    }

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">How do you rate your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()} {data !== null && data.eldersDetails[data.questionsTakenFor].name}'s hearing ability?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <FormControl component="fieldset" className={classes.formControl} required
                    error={errors.hasOwnProperty('hearingAbility') ? true : false}>
                    {/* <FormLabel component="legend">Select</FormLabel> */}
                    <RadioGroup aria-label="quiz" name="quiz" value={hearingAbility} onChange={e => setHearingAbility(e.target.value)}>
                        <FormControlLabel value="Good" control={<Radio />} label="Good" />
                        <FormControlLabel value="Moderate/Not sure - Need a check up" control={<Radio />} label="Moderate/Not sure - Need a check up" />
                        <FormControlLabel value="Poor" control={<Radio />} label="Poor" />
                        <FormControlLabel value="Need a new hearing aid" control={<Radio />} label="Need a new hearing aid" />
                        <FormControlLabel value="Already have a hearing aid" control={<Radio />} label="Already have a hearing aid" />
                    </RadioGroup>
                    <FormHelperText>{errors.hasOwnProperty('hearingAbility') ? errors.hearingAbility.map((e, i) => <span key={"hearingAbility" + i}>{e}</span>) : ""}</FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}