import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import Alert from '@material-ui/lab/Alert';
import apiCaller from './../components/apiCaller';

const { Storage, Modals } = Plugins;
const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "95%"
    },
    button: {
        marginTop: theme.spacing(2),
    },
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const locations =
    [{ "location": "Bangalore" }, { "location": "Bengaluru" }, { "location": "Chandigarh" }, { "location": "Chennai" }, { "location": "Hyderabad" }, { "location": "Indore" }, { "location": "Jaipur" }, { "location": "Vizag" }, { "location": "Kolkata" }, { "location": "Lucknow" }, { "location": "Mumbai" },{ "location": "Navi Mumbai" }, { "location": "Pune" }, { "location": "Delhi" },{ "location": "New Delhi" }, { "location": "Palakkad" }, { "location": "Kasargod" }, { "location": "Ernakulum" }, { "location": "Bhubaneswar" }, { "location": "Mysore" }, { "location": "Mangalore" }, { "location": "Mangaluru" }, { "location": "Ahmedabad" }, { "location": "Thiruvananthapuram" }, { "location": "Tirupati" }, { "location": "Trivandrum" }, { "location": "Vijayawada" }, { "location": "Guntur" }, { "location": "Cochin" }, { "location": "Goa" }, { "location": "Hubli - Dharwad" }, { "location": "Gurgaon" },{ "location": "Gurugram-NCR Haryana" }, { "location": "Ghaziabad" },{ "location": "Ghaziabad-NCR UP" }, { "location": "Guwahati" }, { "location": "Faridabad" },{ "location": "Faridabad-NCR Haryana" }, { "location": "Noida" }, { "location": "Noida-NCR UP" }, { "location": "Puducherry" }, { "location": "Ludhiana" }, { "location": "Add other location" }];

const states = [{ state: 'Andaman and Nicobar Islands' }, { state: 'Andhra Pradesh' }, { state: 'Arunachal Pradesh' }, { state: 'Assam' }, { state: 'Bihar' }, { state: 'Chandigarh' }, { state: 'Chhattisgarh' }, { state: 'Dadra and Nagar Haveli' }, { state: 'Daman and Diu' }, { state: 'Delhi' }, { state: 'Goa' }, { state: 'Gujarat' }, { state: 'Haryana' }, { state: 'Himachal Pradesh' }, { state: 'Jammu' }, { state: 'Jharkhand' }, { state: 'Karnataka' }, { state: 'Kashmir' }, { state: 'Kerala' }, { state: 'Ladakh' }, { state: 'Lakshadweep' }, { state: 'Madhya Pradesh' }, { state: 'Maharashtra' }, { state: 'Manipur' }, { state: 'Meghalaya' }, { state: 'Mizoram' }, { state: 'Nagaland' }, { state: 'Odisha' }, { state: 'Puducherry' }, { state: 'Punjab' }, { state: 'Rajasthan' }, { state: 'Sikkim' }, { state: 'Tamil Nadu' }, { state: 'Telangana' }, { state: 'Tripura' }, { state: 'Uttarakhand' }, { state: 'Uttar Pradesh' }, { state: 'West Bengal' }];



async function setObject(key, data) {
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
}

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}

export default function Location(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [enableOther, setEnableOther] = React.useState(false);
    const [open, toggleOpen] = React.useState(false);
    const [location, setLocation] = React.useState(null);
    const [state, setState] = React.useState(null);
    const [data, setData] = React.useState(null);

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        let response = await apiCaller('post', '/elder-location', { ...location, enableOther, session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            setObject('profile', { ...data, location })
            history.push(response.data.next)
        }
    }

    const handleClose = () => {
        setDialogValue({
            location: '',
            state: '',
            district: '',
        });

        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        location: '',
        state: '',
        district: '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setLocation({
            location: dialogValue.location,
            state: dialogValue.state,
            district: dialogValue.district,
        });

        setEnableOther(true)

        handleClose();
    };

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        });

    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">Specify your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()} {data !== null && data.eldersDetails[data.questionsTakenFor].name}'s location</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}
                <Autocomplete
                    value={location}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            // timeout to avoid instant validation of the dialog's form.
                            setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                    location: newValue,
                                    district: '',
                                    state: '',
                                });
                                setState(null);
                            });
                        } else if (newValue && newValue.inputValue) {
                            toggleOpen(true);
                            setDialogValue({
                                location: newValue.inputValue,
                                district: '',
                                state: '',
                            });
                            setState(null);
                        } else {
                            setLocation(newValue);
                            setEnableOther(false)
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                location: <Button variant="text" fullWidth
                                    startIcon={<AddSharpIcon />}>{`Add "${params.inputValue}"`}</Button>,
                            });
                        }

                        return filtered;
                    }}
                    id="locations"
                    options={locations}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.location;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(option) => option.location}
                    freeSolo
                    style={{ width: 'auto', minWidth: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select location" variant="outlined" required error={errors.hasOwnProperty('location') ? true : false}
                            helperText={errors.hasOwnProperty('location') ? errors.location.map((e, i) => <span key={"location" + i}>{e}</span>) : ""} />
                    )}
                    className={classes.field}
                />
                {enableOther &&
                    <React.Fragment>
                        <TextField
                            required
                            id="district-disabled"
                            label="District"
                            variant="outlined"
                            type="text"
                            value={location.district}
                            disabled
                            className={classes.field}
                            error={errors.hasOwnProperty('district') ? true : false}
                            helperText={errors.hasOwnProperty('district') ? errors.district.map((e, i) => <span key={"district" + i}>{e}</span>) : ""}
                        />
                        <TextField
                            required
                            id="state-disabled"
                            label="State"
                            variant="outlined"
                            type="text"
                            value={location.state}
                            disabled
                            className={classes.field}
                            error={errors.hasOwnProperty('state') ? true : false}
                            helperText={errors.hasOwnProperty('state') ? errors.state.map((e, i) => <span key={"state" + i}>{e}</span>) : ""}
                        />
                    </React.Fragment>
                }
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>

            <Dialog open={open} onClose={handleClose} aria-labelledby="enter-location-details">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="enter-location-details">Add a new location</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Did you miss any location in our list? Please, add it!
                        </DialogContentText>
                        <TextField
                            required
                            margin="dense"
                            id="location"
                            variant="outlined"
                            value={dialogValue.location}
                            onChange={(event) => setDialogValue({ ...dialogValue, location: event.target.value })}
                            label="Location"
                            className={classes.field}
                            fullWidth
                            InputProps={{ minlength: 3, max: 512, type: "text" }}
                        />
                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="dostrict"
                            variant="outlined"
                            value={dialogValue.district}
                            onChange={(event) => setDialogValue({ ...dialogValue, district: event.target.value })}
                            label="District"
                            type="text"
                            className={classes.field}
                            fullWidth
                            InputProps={{ minlength: 3, max: 512, type: "text" }}
                        />
                        <Autocomplete
                            id="state"
                            margin="dense"
                            value={state}
                            onChange={(event, newValue) => {
                                setState(newValue)
                                setDialogValue({ ...dialogValue, state: newValue })
                            }}
                            options={states.map((option) => option.state)}
                            renderInput={(params) => (
                                <TextField {...params} label="State" margin="dense" variant="outlined"
                                    required />
                            )}
                            noOptionsText="No state found with that name"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="contained">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    )
}