import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import RefreshSharpIcon from "@material-ui/icons/RefreshSharp";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import clsx from "clsx";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Plugins, Capacitor } from "@capacitor/core";

import Logo from "./images/Recommendations.svg";
import NotFound from "./pages/NotFound";
import Resume from "./pages/Resume";
import Welcome from "./pages/Welcome";
import OTP from "./pages/OTP";
import EmailID from "./pages/EmailID";
import DoB from "./pages/DoB";
import NumberOfElders from "./pages/NumberOfElders";
import RelationshipStays from "./pages/RelationshipStays";
import Location from "./pages/Location";
import HowMany from "./pages/HowMany";
import RateHealth from "./pages/RateHealth";
import BuyMedicine from "./pages/BuyMedicine";
import CareGiving from "./pages/CareGiving";
import DoctorVisit from "./pages/DoctorVisit";
import HealthCheckup from "./pages/HealthCheckup";
import HearingAbility from "./pages/HearingAbility";
import LeisureActivity from "./pages/LeisureActivity";
import TypesLeisures from "./pages/TypesLeisures";
import Barriers from "./pages/Barriers";
import Concerns from "./pages/Concerns";
import Safety from "./pages/Safety";
import Mobility from "./pages/Mobility";
import Travel from "./pages/Travel";
import ThankYou from "./pages/ThankYou";
import ViewRecommendations from "./pages/ViewRecommendations";
import { Box } from "@material-ui/core";

const { App: AppLaunch, Modals } = Plugins;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    padding: theme.spacing(0.5),
    background: "white",
    display: "block",
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
    width: "240px",
  },
}));

async function getConfirmation(title, message) {
  let confirmRet = await Modals.confirm({ title, message });
  console.log(confirmRet);
  return confirmRet;
}

function App(props) {
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#1a237e",
          },
        },
      }),
    [prefersDarkMode]
  );

  async function launchApp() {
    await AppLaunch.canOpenUrl({ url: "com.tharsha.elderindia" });
    await AppLaunch.openUrl({ url: `com.tharsha.elderindia` });
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link to="/">
          <img
            src={Logo}
            alt="Recommendations by EldersIndia"
            width="100%"
            height="64"
            className={classes.logo}
          />
        </Link>
      </div>
      <Divider />
      <List disablePadding>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            {" "}
            <HomeSharpIcon />{" "}
          </ListItemIcon>
          <ListItemText primary={"Welcome"} onTouchEnd={handleDrawerToggle} />
        </ListItem>
        <ListItem button component={Link} to="/resume">
          <ListItemIcon>
            {" "}
            <RefreshSharpIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={"Resume/View"}
            onTouchEnd={handleDrawerToggle}
          />
        </ListItem>
      </List>
      <Divider />
      <List disablePadding>
        {Capacitor.getPlatform() === "android" ? (
          <ListItem button onClick={launchApp}>
            <ListItemIcon>
              {" "}
              <ExitToAppSharpIcon />{" "}
            </ListItemIcon>
            <ListItemText primary={"Register/Login"} />
          </ListItem>
        ) : (
          <ListItem
            button
            component={Link}
            to={{ pathname: "https://app.eldersindia.com" }}
            target="_blank"
          >
            <ListItemIcon>
              {" "}
              <ExitToAppSharpIcon />{" "}
            </ListItemIcon>
            <ListItemText primary={"Register/Login"} />
          </ListItem>
        )}
        {Capacitor.getPlatform() !== "web" && (
          <ListItem button onClick={closeApp}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            <ListItemText primary="Exit" />
          </ListItem>
        )}
      </List>
      <Box className={classes.bottomPush}>
        <Divider />
        <Box display="flex" justifyContent="center" alignItems="center" pt={1}>
          App version 0.9
        </Box>
      </Box>
    </div>
  );

  async function closeApp(e) {
    e.preventDefault();
    let confirm = await getConfirmation(
      "Confirm",
      "Are you sure want to exit?"
    );
    console.log(confirm);
    if (confirm.value) {
      Plugins.App.exitApp();
    }
  }

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    async function exit() {
      if (Capacitor.isNative) {
        await Plugins.App.addListener("backButton", (e) => {
          // if (window.location.pathname === "/") {
          // Show A Confirm Box For User to exit app or not
          let confirm = getConfirmation(
            "Confirm",
            "Are you sure want to exit?"
          );
          console.log(confirm);
          if (confirm.value) {
            Plugins.App.exitApp();
          }
          // }
        });
      }
    }
    exit();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router basename="/">
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Recommendations
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={clsx(classes.content)}>
            <div className={classes.toolbar} />
            <Switch>
              <Route path="/view-recommendations">
                <ViewRecommendations />
              </Route>
              <Route path="/thank-you-and-continue">
                <ThankYou />
              </Route>
              {/* <Route path="/how-do-you-rate-your-relationship's-mobility"> */}
              <Route path="/how-mobile-do-you-feel-your-relationship-is">
                <Mobility />
              </Route>
              <Route path="/how-often-does-your-relationship-travel">
                <Travel />
              </Route>
              <Route path="/is-safety-of-your-relationship-a-concern-for-you-at-home">
                <Safety />
              </Route>
              <Route path="/what-are-the-concerns-that-you-have-for-your-relationship">
                <Concerns />
              </Route>
              <Route path="/what-are-the-barriers,-that-prevent-your-relationship-from-participating-in-leisure-activities">
                <Barriers />
              </Route>
              <Route path="/what-are-the-types-of-leisure-activities-your-relationship-would-like-to-be-part-of">
                <TypesLeisures />
              </Route>
              <Route path="/how-do-you-rate-your-relationship's-leisure-activities">
                <LeisureActivity />
              </Route>
              <Route path="/how-do-you-rate-your-relationship's-hearing-ability">
                <HearingAbility />
              </Route>
              <Route path="/how-often-does-your-relationship-need-a-health-check-up">
                <HealthCheckup />
              </Route>
              <Route path="/how-often-does-your-relationship-visit-a-doctor">
                <DoctorVisit />
              </Route>
              {/* <Route path="/what-is-the-current-care-giving-support-you-have-for-your-relationship"> */}
              <Route path="/what-is-the-medical-care-support-currently-available-for-your-relationship">
                <CareGiving />
              </Route>
              {/* <Route path="/how-often-do-you-buy-the-medicine-for-your-relationship"> */}
              <Route path="/how-often-are-medicines-procured-for-your-relationship">
                <BuyMedicine />
              </Route>
              <Route path="/how-do-you-rate-the-health-of-your-relationship">
                <RateHealth />
              </Route>
              {/* <Route path="/how-many-non–elderly-live-with-your-elder"> */}
              <Route path="/does-your-relationship-have-any-non-elderly-person-living-with-him-for-support">
                <HowMany />
              </Route>
              <Route path="/specify-elder-location">
                <Location />
              </Route>
              <Route path="/does-your-elder-live-with-you">
                <RelationshipStays />
              </Route>
              <Route path="/number-of-elders">
                <NumberOfElders />
              </Route>
              <Route path="/dob">
                <DoB />
              </Route>
              <Route path="/email">
                <EmailID />
              </Route>
              <Route path="/otp">
                <OTP />
              </Route>
              <Route path="/resume">
                <Resume />
              </Route>
              <Route path="/">
                <Welcome />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
