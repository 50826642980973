import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { Plugins } from '@capacitor/core';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import apiCaller from './../components/apiCaller';


const { Storage, Modals } = Plugins;

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
        width: "98%"
    },
    title: {
        marginTop: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

// async function setObject(key, data) {
//     await Storage.set({
//         key,
//         value: JSON.stringify(data)
//     });
// }

async function getObject(key) {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
}

async function showAlert(title, message) {
    await Modals.alert({ title, message });
}


export default function RateHealth(props) {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null);

    const [healthy, setHealthy] = React.useState({
        "No ailments": true,
    });
    const [lifeStyle, setLifeStyle] = React.useState({
        Diabetes: false,
        Hypertension: false,
        Thyroid: false,
        Arthritis: false,
        "Heart conditions": false,

    });
    const [neuroLogical, setNeuroLogical] = React.useState({
        Dementia: false,
        "Alzheimer's": false,
        "Parkinson's": false,
        Stroke: false,
        Epilepsy: false,

    });
    const [physiological, setPhysiological] = React.useState({
        Schizophrenia: false,
        Bipolar: false,
        Anxiety: false,
        Stress: false,
    });
    const [terminal, setTerminal] = React.useState({
        "Cancer (advanced)": false,
        "Autoimmune disorder": false,
        "Chronic Obstructive Pulmonary Disease (COPD)": false,
        Stress: false,
    });
    const [shortTerm, setShortTerm] = React.useState({
        Fracture: false,
        Dengue: false,
        Typhoid: false,
    });

    async function handleFormSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        let response = await apiCaller('post', '/how-do-you-rate-the-health-of-your-relationship', { healthy, lifeStyle, neuroLogical, physiological, terminal, shortTerm, session: data.session });

        if (response.data === undefined) {
            showAlert('Something went wrong', 'We encountered error at out servers and the same has been notified to admin.');
            setDisabled(false);
            setLoading(false);
        }
        else if (response.data.errors !== undefined) {
            setErrors(response.data.errors);
            setDisabled(false);
            setLoading(false);
        }
        else {
            setDisabled(false);
            setLoading(false);
            history.push(response.data.next)
        }
    }

    const handleHealthy = (event) => {
        setHealthy({ ...healthy, [event.target.name]: event.target.checked });
        setLifeStyle({ ...lifeStyle, ...Object.keys(lifeStyle).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
        setNeuroLogical({ ...neuroLogical, ...Object.keys(neuroLogical).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
        setPhysiological({ ...physiological, ...Object.keys(physiological).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
        setTerminal({ ...terminal, ...Object.keys(terminal).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
        setShortTerm({ ...shortTerm, ...Object.keys(shortTerm).reduce((t, c, i) => ({ ...t, [c]: false }), {}) });
    };
    const handleLifeStyle = (event) => {
        setLifeStyle({ ...lifeStyle, [event.target.name]: event.target.checked });
        healthy["No ailments"] === true && setHealthy({ ...healthy, "No ailments": false });
    };
    const hadleNeurological = (event) => {
        setNeuroLogical({ ...neuroLogical, [event.target.name]: event.target.checked });
        healthy["No ailments"] === true && setHealthy({ ...healthy, "No ailments": false });
    };
    const handlePhysiological = (event) => {
        setPhysiological({ ...physiological, [event.target.name]: event.target.checked });
        healthy["No ailments"] === true && setHealthy({ ...healthy, "No ailments": false });
    };
    const handleTerminal = (event) => {
        setTerminal({ ...terminal, [event.target.name]: event.target.checked });
        healthy["No ailments"] === true && setHealthy({ ...healthy, "No ailments": false });
    };
    const handleShortTerm = (event) => {
        setShortTerm({ ...shortTerm, [event.target.name]: event.target.checked });
        healthy["No ailments"] === true && setHealthy({ ...healthy, "No ailments": false });
    };

    React.useEffect(() => {
        getObject('profile').then(data => {
            data !== null && setData(data);
        })
    }, [])

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography fontWeight={700} variant="h5" align="center">How do rate the health of your {data !== null && data.eldersDetails[data.questionsTakenFor].relationship.toLowerCase()} {data !== null && data.eldersDetails[data.questionsTakenFor].name}?</Typography>
            <form className={classes.form} onSubmit={handleFormSubmit}>
                {Object.keys(errors).length !== 0 && <Alert severity="error" onClose={() => { setErrors({}); }}>Please correct the errors and re-submit.</Alert>}

                <Typography display="block" color="primary" variant="subtitle1">Healthy</Typography>
                <Box display="flex" flexDirection="row" marginLeft={2}>
                    <FormControlLabel
                        control={<Checkbox checked={healthy["No ailments"]} onChange={handleHealthy} icon={<FavoriteBorder fontSize="small" />} checkedIcon={<Favorite fontSize="small" />} name="No ailments" />}
                        label="No ailments"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>Lifestyle diseases</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={lifeStyle.Diabetes}
                                onChange={handleLifeStyle}
                                name="Diabetes"
                                size="small"
                            />
                        }
                        label="Diabetes"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={lifeStyle.Hypertension}
                                onChange={handleLifeStyle}
                                name="Hypertension"
                                size="small"
                            />
                        }
                        label="Hypertension"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={lifeStyle.Thyroid}
                                onChange={handleLifeStyle}
                                name="Thyroid"
                                size="small"
                            />
                        }
                        label="Thyroid"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={lifeStyle.Arthritis}
                                onChange={handleLifeStyle}
                                name="Arthritis"
                                size="small"
                            />
                        }
                        label="Arthritis"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={lifeStyle['Heart conditions']}
                                onChange={handleLifeStyle}
                                name="Heart conditions"
                                size="small"
                            />
                        }
                        label="Heart conditions"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>Neurological conditions</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={neuroLogical.Dementia}
                                onChange={hadleNeurological}
                                name="Dementia"
                                size="small"
                            />
                        }
                        label="Dementia"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={neuroLogical["Alzheimer's"]}
                                onChange={hadleNeurological}
                                name="Alzheimer's"
                                size="small"
                            />
                        }
                        label="Alzheimer's"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={neuroLogical["Parkinson's"]}
                                onChange={hadleNeurological}
                                name="Parkinson's"
                                size="small"
                            />
                        }
                        label="Parkinson's"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={neuroLogical.Stroke}
                                onChange={hadleNeurological}
                                name="Stroke"
                                size="small"
                            />
                        }
                        label="Stroke"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={neuroLogical.Epilepsy}
                                onChange={hadleNeurological}
                                name="Epilepsy"
                                size="small"
                            />
                        }
                        label="Epilepsy"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>Psychological conditions</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={physiological.Schizophrenia}
                                onChange={handlePhysiological}
                                name="Schizophrenia"
                                size="small"
                            />
                        }
                        label="Schizophrenia"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={physiological.Bipolar}
                                onChange={handlePhysiological}
                                name="Bipolar"
                                size="small"
                            />
                        }
                        label="Bipolar"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={physiological.Anxiety}
                                onChange={handlePhysiological}
                                name="Anxiety"
                                size="small"
                            />
                        }
                        label="Anxiety"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={physiological.Stress}
                                onChange={handlePhysiological}
                                name="Stress"
                                size="small"
                            />
                        }
                        label="Stress"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>Terminal conditions</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={terminal["Cancer (advanced)"]}
                                onChange={handleTerminal}
                                name="Cancer (advanced)"
                                size="small"
                            />
                        }
                        label="Cancer (advanced)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={terminal["Autoimmune disorder"]}
                                onChange={handleTerminal}
                                name="Autoimmune disorder"
                                size="small"
                            />
                        }
                        label="Autoimmune disorder"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={terminal["Chronic Obstructive Pulmonary Disease (COPD)"]}
                                onChange={handleTerminal}
                                name="Chronic Obstructive Pulmonary Disease (COPD)"
                                size="small"
                            />
                        }
                        label="Chronic Obstructive Pulmonary Disease (COPD)"
                    />
                </Box>
                <Typography display="block" color="primary" variant="subtitle1" className={classes.title}>Short term condition</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignContent="flex-start" marginLeft={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={shortTerm.Fracture}
                                onChange={handleShortTerm}
                                name="Fracture"
                                size="small"
                            />
                        }
                        label="Fracture"
                    />
                     <FormControlLabel
                        control={
                            <Checkbox
                                checked={shortTerm.Dengue}
                                onChange={handleShortTerm}
                                name="Dengue"
                                size="small"
                            />
                        }
                        label="Dengue"
                    />
                     <FormControlLabel
                        control={
                            <Checkbox
                                checked={shortTerm.Typhoid}
                                onChange={handleShortTerm}
                                name="Typhoid"
                                size="small"
                            />
                        }
                        label="Typhoid"
                    />
                </Box>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    endIcon={loading ? <CircularProgress size={24} /> : <KeyboardArrowRightSharpIcon />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </form>
        </Box>
    )
}